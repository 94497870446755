.filters-row .ant-form-item
  width: 95%
.Dashboard .ant-card
  margin: 5px
.ant-layout
  width: 100%
  min-height: 100%
  position: absolute
.filters-row .ant-checkbox-group
  width: 100%
  inline-size: max-content
.auth-form
  max-width: 400px
  margin: 150px auto
  padding: 20px 15px
  display: block
  border: #eee
  border-radius: 5px
.auth-form h2
  color: rgba(0, 0, 0, 0.65)
.auth-form button
  width: 100%
.auth-form .login-form-forgot
  float: right
.ant-layout-content
  background: #ffffff
  padding: 10px
  margin: 10px
.dashboard-tabs .ant-tabs-nav-wrap .ant-tabs-nav > div > div
  text-align: left
  padding-left: 10px
  margin-bottom: 0
.ant-tabs-content-holder
  width: 0
.PlayerContainer
  position: fixed
  bottom: -200px
  width: 100%
  z-index: 5
  left: 0px
  transition: all .8s
.PlayerContainer.showplayer
  bottom: 0
.close-btn
  position: absolute
  right: 0
  top: -8px
  font-size: 26px
  cursor: pointer
.rhap_progress-section
  width: 90%
  margin-left: 5%
.ant-btn.current-playing svg
  color: #096dd9
  animation: playAnim 1s infinite
.tox-tinymce
  margin: 2px 15px
.one-line-table-data
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
th.ant-table-cell ,.centered-content
  text-align: center !important
.ant-table-content
  overflow: auto
.person-remove-key
  font-size: 20px
  margin-top: 38px
.ant-table-tbody tr.ant-table-row.deleted-row td
  background: #ffcece !important
.remove-buttons .ant-btn
  width: 100%
  margin-top: 10px
.remove-modal
  .ant-modal-body
    padding: 15px 15px 12px
  .ant-modal-confirm-content
    margin-left: 0 !important
@keyframes playAnim
  0%
    transform: scale(1)
  25%
    transform: scale(1.25)
  50%
    transform: scale(1.5)
  75%
    transform: scale(1.25)
  100%
    transform: scale(1)
